import {
	Breadcrumb,
	Select,
	TextArea,
	Button,
	Input,
	Checkbox,
	EditModelForm,
	EditFormSection,
	LoadingFullScreen,
} from 'components'
import { File } from 'components/File'
import { getEntry, getFilm, updateEntry, createEntry, uploadEntryAttachment, downloadEntryAttachment } from 'api/films'
import { getViewerStatuses, getEntryStatuses } from 'api/statuses'
import { getCurrentAwardsYearCategories, getAwardsYearRounds } from 'api/awardsyears'
import { notification } from 'utils/notifications'
import { PersonDialog } from './PersonDialog'

export const EditEntry = ({ attrs: { filmId, entryId } }) => {
	let ready
	let film
	let model
	let awardsYear
	let entryStatuses
	let viewerStatuses
	let categories
	entryId = parseInt(entryId)
	getFilm(filmId).then((data) => {
		film = data
		awardsYear = film.year
	})
	getViewerStatuses().then((data) => {
		viewerStatuses = data
	})
	getEntryStatuses().then((data) => {
		entryStatuses = data
	})
	getCurrentAwardsYearCategories().then((data) => {
		categories = data
	})

	let rounds
	getAwardsYearRounds(27).then((res) => {
		rounds = res.map((z) => {
			return { value: z.id, label: z.name }
		})
	})

	if (entryId) {
		getEntry(filmId, entryId).then((data) => {
			model = data
			ready = true
		})
	} else {
		model = {
			entryStatusId: 1,
			entryAttachments: [],
			entryPersonFilms: [],
			roundEntries: [],
		}
		ready = true
	}

	const personOpen = flyd.stream(false)
	let selectedPersonIx = 0

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (entryId) {
			updateEntry(filmId, model.id, model)
				.then(() => {
					notification.success({ title: 'Entry updated' })
					m.route.set(`/films/${filmId}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		} else {
			createEntry(filmId, model)
				.then((data) => {
					notification.success({ title: 'Entry created' })
					m.route.set(`/films/${filmId}`)
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: err.response })
				})
		}
	}

	let uploadingEntryAttachment
	const onUploadEntryAttachment = (file) => {
		if (file) {
			uploadingEntryAttachment = true
			uploadEntryAttachment(filmId, model.id, file)
				.then((a) => {
					uploadingEntryAttachment = false
					model.entryAttachments.push({
						name: a.substring(a.lastIndexOf('/') + 1),
						type: '',
						path: a,
						id: 0,
					})
				})
				.catch((err) => {
					console.log(err)
					notification.error({ title: 'Error - unable to upload' })
					uploadingEntryAttachment = false
				})
		}
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Films',
							href: '/films',
						},
						{
							title: film ? film.title : '...',
							href: `/films/${filmId}`,
						},
						{
							title: !entryId ? 'Create' : ready ? `Edit ${model.title}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: entryId ? 'Edit Entry' : 'Create Entry',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-4', [
												model.votingCategoryId
													? m(Input, {
															label: 'Category',
															value: model.votingCategoryName,
															type: 'readonly',
													  })
													: m(
															'select',
															{
																value: model.votingCategoryId,
																required: true,
																onchange(val) {
																	model.votingCategoryId = parseInt(val.target.value)
																	model.votingCategoryName = categories.find((x) => {
																		return x.id === model.votingCategoryId
																	}).name
																},
															},
															[
																m(
																	'option',
																	{
																		value: 0,
																	},
																	'Select... '
																),
																categories &&
																	categories.map((x) => {
																		return m(
																			'option',
																			{
																				value: x.id,
																			},
																			x.name
																		)
																	}),
															]
													  ),
												,
											]),
											m('.col-span-2', [
												m(Input, {
													label: 'Awards Year',
													value: awardsYear,
													type: 'readonly',
												}),
											]),
											m('.col-span-3', [
												entryStatuses &&
													m(Select, {
														label: 'Entry Status',
														value: model.entryStatusId,
														required: true,
														choices: entryStatuses.map((ay) => {
															return {
																label: ay.name,
																value: ay.id,
															}
														}),
														onchange(val) {
															model.entryStatusId = parseInt(val)
														},
													}),
											]),
											m('.col-span-3', [
												viewerStatuses &&
													m(Select, {
														label: 'Viewer Status',
														value: model.viewerStatusId,
														required: false,
														choices: viewerStatuses.map((ay) => {
															return {
																label: ay.name,
																value: ay.id,
															}
														}),
														onchange(val) {
															model.viewerStatusId = parseInt(val)
														},
													}),
											]),
											m('.col-span-6', [
												m(TextArea, {
													label: 'Statement',
													value: model.statement,
													name: 'statement',
													rows: 8,
													oninput: (content) => {
														model.statement = content
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'SubTeam',
													value: model.subTeam,
													name: 'subTeam',
													oninput(value) {
														model.subTeam = value ? value.toUpperCase() : ''
													},
												}),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Entry Attachments',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-5.gap-6', [
											model.entryAttachments.map((ea, eax) => {
												return [
													m(
														'.col-span-2',
														m(Input, {
															label: 'Name',
															value: ea.name,
															oninput(value) {
																model.entryAttachments[eax].name = value
															},
														})
													),
													m(Input, {
														label: 'Type',
														value: ea.type,
														oninput(value) {
															model.entryAttachments[eax].type = value
														},
													}),
													m(
														'.flex.items-end.justify-center',
														m(
															Button,
															{
																size: 'xs',
																type: 'button',
																variant: 'info',
																onclick() {
																	downloadEntryAttachment(ea.id, ea.name)
																},
															},
															'Download'
														)
													),
													m(
														'.flex.items-end.justify-center',
														m(
															Button,
															{
																size: 'xs',
																type: 'button',
																variant: 'delete',
																onclick() {
																	if (
																		confirm(
																			'Are you sure you want to delete this file? If you confirm, you will still need to click Save'
																		)
																	) {
																		model.entryAttachments.splice(eax, 1)
																	}
																},
															},
															'Delete'
														)
													),
												]
											}),
											m('div', [
												m(
													'.bg-gray-100.p-1.text-right',
													uploadingEntryAttachment
														? m(LoadingFullScreen)
														: m(
																File,
																{
																	type: 'file',
																	name: 'file',
																	classes: ['my-2'],
																	label: 'Upload',
																	oninput: (val) => {
																		onUploadEntryAttachment(val)
																	},
																},
																'Add Attachment'
														  )
												),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'People',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('table.table.w-full', [
											m('tr', [
												m('th', 'Name'),
												m('th', 'Character'),
												m('th', 'Role'),
												m('th.w-24', 'Nationality'),
												m('th.w-24', 'Pronouns'),
												m('th.w-16', 'Order'),
												m('th', ''),
											]),
											model.entryPersonFilms.map((ea, eix) => {
												return m('tr', [
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.name,
															type: 'readonly',
															tighter: true,
														})
													),
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.character,
															type: 'text',
															tighter: true,
															oninput(value) {
																model.entryPersonFilms[eix].character = value
															},
														})
													),
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.role,
															type: 'text',
															tighter: true,
															oninput(value) {
																model.entryPersonFilms[eix].role = value
															},
														})
													),
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.nationality,
															type: 'text',
															tighter: true,
															oninput(value) {
																model.entryPersonFilms[eix].nationality = value
															},
														})
													),
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.pronouns,
															type: 'text',
															tighter: true,
															oninput(value) {
																model.entryPersonFilms[eix].pronouns = value
															},
														})
													),
													m(
														'td',
														m(Input, {
															label: '',
															value: ea.sortOrder,
															type: 'number',
															tighter: true,
															oninput(value) {
																model.entryPersonFilms[eix].sortOrder = value
															},
														})
													),
													m(
														'td',
														m(
															Button,
															{
																size: 'xs',
																type: 'button',
																variant: 'delete',
																onclick() {
																	if (
																		confirm(
																			'Are you sure you want to delete this person? If you confirm, you will still need to click Save'
																		)
																	) {
																		model.entryPersonFilms.splice(eix, 1)
																	}
																},
															},
															'Delete'
														)
													),
												])
											}),

											m('tr', [
												m(
													'td.bg-gray-100.p-1.text-right',
													{ colspan: 7 },
													m(
														Button,
														{
															size: 'xs',
															type: 'button',
															onclick() {
																model.entryPersonFilms.push({
																	name: '',
																	character: '',
																	role: '',
																	nationality: '',
																	pronouns: '',
																})
																selectedPersonIx = model.entryPersonFilms.length - 1
																personOpen(true)
															},
														},
														'Add Person'
													)
												),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Rounds',
									description: '',
									extra: null,
								},
								[
									ready &&
										m('.grid.grid-cols-3.gap-6', [
											model.roundEntries.map((ea, eax) => {
												return [
													m('.shadow.bg-gray-50.rounded.rounded-sm.p-4', [
														m(
															'.col-span-4',
															rounds && ea.roundId === 0
																? m(Select, {
																		label: 'Round',
																		value: ea.roundId,
																		required: true,
																		choices: rounds,
																		onchange(val, lbl) {
																			model.roundEntries[eax].roundId = parseInt(val)
																			model.roundEntries[eax].roundName = lbl
																			model.roundEntries[eax].votes = 0
																		},
																  })
																: m('div', [m('.text-lg', ea.roundName), m('.text-sm', `[${ea.votes} Votes]`)])
														),
														m(
															'.flex.items-end.justify-center',
															ea.roundName !== 'Viewing' &&
																ea.votes === 0 &&
																m(
																	Button,
																	{
																		size: 'xs',
																		type: 'button',
																		variant: 'delete',
																		onclick() {
																			if (
																				confirm(
																					'Are you sure you want to delete this round? If you confirm, you will still need to click Save'
																				)
																			) {
																				model.roundEntries.splice(eax, 1)
																			}
																		},
																	},
																	'Remove'
																)
														),
													]),
												]
											}),
										]),
									m('div.flex.flex-end.p-1', [
										m(
											Button,
											{
												size: 'xs',
												type: 'button',
												onclick() {
													model.roundEntries.push({
														roundName: '',
														roundId: 0,
														entryId: model.id,
													})
												},
											},
											'Add To Round'
										),
									]),
									,
								]
							),
						]
					),
				personOpen() &&
					m(PersonDialog, {
						open: personOpen,
						onsubmit: (person) => {
							model.entryPersonFilms[selectedPersonIx].name = person.name
							model.entryPersonFilms[selectedPersonIx].personId = person.id
							model.entryPersonFilms[selectedPersonIx].pronouns = person.pronouns
						},
					}),
			]
		},
	}
}
