import { Table, Row, Cell, CellBool, LoadingFullScreen, Pagination } from 'components'
import { downloadFilmAttachment } from 'api/films'

export const AttachmentsTab = ({ attrs: { id } }) => {
	const cols = [{ label: 'Id' }, { label: 'Name' }, { label: 'Type' }, { label: 'Path' }]
	let attachmentDownloading = []
	return {
		view({ attrs: { id, attachments } }) {
			return m('.h-auto', [
				m(
					Table,
					{
						cols: cols,
					},
					[
						attachments
							? attachments.map((row, ix) => {
									return m(
										Row,
										{
											clickable: true,
											onclick: (e) => {
												attachmentDownloading[ix] = true
												downloadFilmAttachment(row.id, row.name).then(() => {
													attachmentDownloading[ix] = false
												})
											},
											bg: 'bg-white',
										},
										[
											m(Cell, row.id),
											m(Cell, row.name),
											m(Cell, row.type),
											m(
												Cell,
												attachmentDownloading[ix]
													? m(LoadingFullScreen, { style: 'height:12px;transform: scale(.5);' })
													: row.path
											),
										]
									)
							  })
							: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
					]
				),
			])
		},
	}
}
