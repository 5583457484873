import {
	Breadcrumb,
	Button,
	Select,
	TextArea,
	Input,
	Checkbox,
	EditModelForm,
	EditFormSection,
	LoadingFullScreen,
} from 'components'
import { File } from 'components/File'
import {
	getAwardsYearRound,
	getAwardsYear,
	updateRound,
	addRound,
	importRoundVoters,
	exportRoundVoters,
	importRoundEntries,
	exportRoundEntries,
	exportRoundVotes,
} from 'api/awardsyears'
import { getRules } from 'api/rules'
import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'

export const EditAwardsYearRound = ({ attrs: { id, roundId } }) => {
	let ready
	let round
	let awardsYear

	if (id) {
		getAwardsYear(id).then((data) => {
			awardsYear = data
		})
		getAwardsYearRound(id, roundId).then((data) => {
			round = data
			round.startDateDate = round.startDate ? DateTime.fromISO(round.startDate).toLocal().toISODate() : ''
			round.startDateTime = round.startDate
				? DateTime.fromISO(round.startDate).toLocal().toISOTime({
						includeOffset: false,
						suppressMilliseconds: true,
						suppressSeconds: true,
				  })
				: '00:00'
			round.endDateDate = round.endDate ? DateTime.fromISO(round.endDate).toLocal().toISODate() : ''
			round.endDateTime = round.endDate
				? DateTime.fromISO(round.endDate).toLocal().toISOTime({
						includeOffset: false,
						suppressMilliseconds: true,
						suppressSeconds: true,
				  })
				: '00:00'
			ready = true
		})
	} else {
		round = {}
		ready = true
	}

	let rules
	getRules().then((data) => {
		rules = data.data.map((z) => {
			return { value: z.id, label: z.name + (z.ruleTypeName ? ` [${z.ruleTypeName}]` : '') }
		})
	})

	const createOrUpdate = (e) => {
		e.preventDefault()
		if (round.id) {
			updateRound(id, round.id, round)
				.then((res) => {
					notification.success({ title: 'Round updated' })
					m.route.set(`/awardsyears/${id}?tab=Rounds`)
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		} else {
			addRound(id, round)
				.then((res) => {
					notification.success({ title: 'Round created' })
					m.route.set(`/awardsyears/${id}?tab=Rounds`)
				})
				.catch((err) => {
					notification.error({ title: err.response })
				})
		}
	}

	const importForm = {
		file: null,
		error: null,
	}
	let savingImportVoters = false
	const onImportVoters = () => {
		if (importForm.file) {
			savingImportVoters = true
			importRoundVoters(id, roundId, importForm.file)
				.then((msg) => {
					notification.success({ title: 'Imported voters' })
					savingImportVoters = false
					round.voters = msg.voters
					importForm.file = null
				})
				.catch((err) => {
					notification.error({ title: 'Error - unable to import' })
					savingImportVoters = false
				})
		} else {
			alert('File must be selected')
		}
	}
	const onDownloadVoters = () => {
		exportRoundVoters(id, roundId)
	}

	const importFormEntries = {
		file: null,
		error: null,
	}
	let savingImportEntries = false
	const onImportEntries = () => {
		if (importFormEntries.file) {
			savingImportEntries = true
			importRoundEntries(id, roundId, importFormEntries.file)
				.then((msg) => {
					notification.success({ title: 'Imported entries' })
					round.entries = msg.entries
					importFormEntries.file = null
					savingImportEntries = false
				})
				.catch((err) => {
					notification.error({ title: 'Error - unable to import' })
					savingImportEntries = false
				})
		} else {
			alert('File must be selected')
		}
	}
	const onDownloadEntries = () => {
		exportRoundEntries(id, roundId)
	}

	let downloadingVotes = false
	let importingEntries = false
	let importingVoters = false

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Awards Years',
							href: '/awardsyears',
						},
						{
							title: awardsYear ? awardsYear.year : '...',
							href: `/awardsyears/${id}`,
						},
						{
							title: 'Rounds',
							href: `/awardsyears/${id}?tab=Rounds`,
						},
						{
							title: !roundId ? 'Create' : ready ? `Edit ${round.name}` : 'Edit',
						},
					],
				}),

				ready &&
					m(
						EditModelForm,
						{
							onsubmit: createOrUpdate,
						},
						[
							m(
								EditFormSection,
								{
									heading: roundId ? 'Edit Round' : 'Create Round',
									description: '',
									extra: m(
										Button,
										{
											type: 'button',
											classes: ['mt-6'],
											loading: downloadingVotes,
											onclick() {
												downloadingVotes = true
												exportRoundVotes(id, roundId).then(() => {
													downloadingVotes = false
												})
											},
										},
										'Download Votes'
									),
								},
								[
									m('.flex.flex-col.gap-3', [
										m('.grid.grid-cols-3.gap-x-3.gap-y-1', [
											m(
												'.col-span-2',
												m(Input, {
													label: 'Name',
													value: round.name,
													required: true,
													type: 'text',
													oninput: (value) => {
														round.name = value
													},
												})
											),
											m(Input, {
												label: 'Number',
												value: round.number,
												required: true,
												type: 'number',
												oninput: (value) => {
													round.number = value
												},
											}),
											m(Input, {
												label: 'Start',
												value: round.startDateDate,
												type: 'date',
												required: true,
												oninput: (value) => {
													round.startDateDate = value
													round.startDate = DateTime.fromISO(round.startDateDate + ' ' + round.startDateTime)
														.toUTC()
														.toISO()
												},
											}),
											m(Input, {
												label: 'Start Time',
												value: round.startDateTime,
												type: 'time',
												required: true,
												oninput: (value) => {
													round.startDateTime = value
													round.startDate = DateTime.fromISO(round.startDateDate + 'T' + round.startDateTime)
														.toUTC()
														.toISO()
												},
											}),
											m(Input, { label: 'UTC Start', type: 'readonly', value: round.startDate }),
											m(Input, {
												label: 'End',
												name: 'enddate',
												value: round.endDateDate,
												type: 'date',
												required: true,
												oninput: (value) => {
													round.endDateDate = value
													round.endDate = DateTime.fromISO(round.endDateDate + 'T' + round.endDateTime)
														.toUTC()
														.toISO()
												},
											}),
											m(Input, {
												label: 'End Time',
												value: round.endDateTime,
												type: 'time',
												required: true,
												oninput: (value) => {
													round.endDateTime = value
													round.endDate = DateTime.fromISO(round.endDateDate + 'T' + round.endDateTime)
														.toUTC()
														.toISO()
												},
											}),
											m(Input, { label: 'UTC End', type: 'readonly', value: round.endDate }),
										]),
										m('.grid.grid-cols-3.gap-x-3', [
											m('div', [
												m(Checkbox, {
													label: 'Viewing?',
													checked: round.isViewingRound,
													onchange() {
														round.isViewingRound = !round.isViewingRound
													},
												}),
											]),
											m('div', [
												m(Checkbox, {
													label: 'Nominations?',
													checked: round.isNominationsRound,
													onchange() {
														round.isNominationsRound = !round.isNominationsRound
													},
												}),
											]),
											m('div', [
												m(Checkbox, {
													label: 'Jury?',
													checked: round.isJuryRound,
													onchange() {
														round.isJuryRound = !round.isJuryRound
													},
												}),
											]),
											m('div', [
												m(Checkbox, {
													label: 'Show films on dash?',
													checked: round.isShowFilmsOnDashboard,
													onchange() {
														round.isShowFilmsOnDashboard = !round.isShowFilmsOnDashboard
													},
												}),
											]),
											m('div', [
												m(Checkbox, {
													label: 'Allow voting for none?',
													checked: round.isVotingForNoneAllowed,
													onchange() {
														round.isVotingForNoneAllowed = !round.isVotingForNoneAllowed
													},
												}),
											]),
											m('div', [
												m(Checkbox, {
													label: 'Hidden?',
													checked: round.isHidden,
													onchange() {
														round.isHidden = !round.isHidden
													},
												}),
											]),
										]),
										m('.grid.gap-x-3', [
											m(TextArea, {
												value: round.description,
												label: 'Description',
												name: 'description',
												rows: 3,
												oninput: (content) => {
													round.description = content
												},
											}),
										]),

										// m('.grid.grid-cols-2.gap-x-3', [
										// 	m(
										// 		Button,
										// 		{
										// 			type: 'button',
										// 			onclick: () => {
										// 				onDownloadVoters()
										// 			},
										// 		},
										// 		'Download Round Voters'
										// 	),
										// ]),
									]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Round Categories',
									description: '',
									extra: null,
								},
								[
									awardsYear.votingCategories &&
										m('div', [
											round.roundVotingCategories.map((ea, eix) => {
												return m('.grid.grid-cols-2.gap-2', [
													m(Select, {
														label: 'Category',
														value: ea.votingCategoryId,
														required: true,
														choices: awardsYear.votingCategories.map((vc) => {
															return { label: vc.shortName, value: vc.id }
														}),
														onchange(val) {
															round.roundVotingCategories[eix].votingCategoryId = parseInt(val)
														},
													}),
													m(Input, {
														label: 'Sort',
														value: ea.sortOrder,
														type: 'text',
														oninput(value) {
															round.roundVotingCategories[eix].sortOrder = value
														},
													}),
													m('.col-span-2.flex', [
														rules &&
															m(Select, {
																label: 'Rules',
																value: ea.roundVotingCategoryRules.map((r) => {
																	return r.ruleId
																}),
																required: true,
																subtype: 'multiple',
																choices: rules,
																onchange(val) {
																	round.roundVotingCategories[eix].roundVotingCategoryRules = val.map((z) => {
																		return {
																			id: 0,
																			ruleId: parseInt(z),
																		}
																	})
																	console.log(round.roundVotingCategories[eix])
																},
															}),
													]),
												])
											}),

											m('.w-full.border-t.mt-2.pt-2', [
												m(
													'.text-right',
													m(
														Button,
														{
															size: 'xs',
															type: 'button',
															onclick() {
																round.roundVotingCategories.push({
																	votingCategoryId: 0,
																	roundVotingCategoryRules: [],
																	sortOrder: 1,
																	id: 0,
																})
															},
														},
														'Add Round Category'
													)
												),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Entries',
									description: 'Upload file must have a column called EntryId',
									extra: null,
									noSave: true,
								},
								[
									ready &&
										m('div', [
											m('.text-lg', `Current Entries: ${round.entries}`),
											m('.flex.justify-center.items-center.gap-x-2', [
												m(
													Button,
													{
														type: 'button',
														onclick: () => {
															onDownloadEntries()
														},
													},
													'Download Round Entries'
												),
												savingImportEntries
													? m(LoadingFullScreen)
													: m(
															File,
															{
																type: 'file',
																name: 'file',
																classes: ['py-2', 'text-lg'],
																label: 'Upload',
																value: importFormEntries.file,
																oninput: (val) => {
																	importFormEntries.error = null
																	importFormEntries.file = val

																	if (importFormEntries.file) {
																		onImportEntries()
																	}
																},
															},
															'Import Round Entries'
													  ),
											]),
										]),
								]
							),
							m(
								EditFormSection,
								{
									heading: 'Voters',
									description: 'Upload file must have a column called UserId',
									extra: null,
									noSave: true,
								},
								[
									m('div', [
										m('.text-lg', `Current Voters: ${round.voters}`),
										m('.flex.justify-center.items-center.gap-x-2', [
											m(
												Button,
												{
													type: 'button',
													onclick: () => {
														onDownloadVoters()
													},
												},
												'Download Round Voters'
											),
											savingImportVoters
												? m(LoadingFullScreen)
												: m(
														File,
														{
															type: 'file',
															name: 'file',
															classes: ['py-2', 'text-lg'],
															label: 'Upload',
															value: importForm.file,
															oninput: (val) => {
																importForm.error = null
																importForm.file = val

																if (importForm.file) {
																	onImportVoters()
																}
															},
														},
														'Import Round Voters'
												  ),
										]),
									]),
								]
							),
						]
					),
			]
		},
	}
}
